// 两数相加
export function add(a, b) {
  var c = 0;
  var d = 0;
  var e = 1;
  try {
    c = a.toString().split('.')[1].length  // 小数点后长度
  } catch (f) { }
  try {
    d = b.toString().split('.')[1].length
  } catch (f) { }
  return e = Math.pow(10, Math.max(c, d)), (mul(a, e) + mul(b, e)) / e
}

// 两数相减
export function sub(a, b) {
  var c = 0;
  var d = 0;
  var e = 1;
  try {
    c = a.toString().split('.')[1].length
  } catch (f) { }
  try {
    d = b.toString().split('.')[1].length
  } catch (f) { }
  return e = Math.pow(10, Math.max(c, d)), (mul(a, e) - mul(b, e)) / e
}

// 两数相乘
export function mul(a, b) {
  var c = 0;
  var d = a.toString();
  var e = b.toString();
  try {
    c += d.split('.')[1].length
  } catch (f) { }
  try {
    c += e.split('.')[1].length
  } catch (f) { }
  return Number(d.replace('.', '')) * Number(e.replace('.', '')) / Math.pow(10, c)
}

// 两数相除
export function div(a, b) {
  var c = 1;
  var d = 1;
  var e = 0;
  var f = 0;
  try {
    e = a.toString().split('.').length
  } catch (g) { }
  try {
    f = b.toString().split('.').length
  } catch (g) { }
  return c = Number(a.toString().replace('.', '')), d = Number(b.toString().replace('.', '')), mul(c / d, Math.pow(10, f - e))
}

/**
  * 格式化金额 
  * @param v 金额
  * @param c 保留小数位
  * @param d 分隔符
  * @param t 分隔符
  * @returns {string}
*/
export function fmtMoney(v, c, d, t) {
  if (typeof (v) === 'number') {
    v = v.toString()
  }
  v = v.replace(/,/g, '')
  v *= 1
  var p = v < 0 ? '-' : ''
  c = c || 2
  v = div(Math.round(v * 10000000), 10000000)
  c = Math.abs(c) + 1 ? c : 0
  d = d || '.'
  t = t || ','
  var m = (/(\d+)(?:(\.\d+)|)/.exec(v + ''))
  var x = m[1].length > 3 ? m[1].length % 3 : 0
  return p + (x ? m[1].substring(0, x) + t : '') + m[1].substring(x).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + (+m[2] || 0).toFixed(c).substring(2) : '')
}

// 金额去格式化
export function unfmtAmt(s) {
  return s.replace(/,/g, '')
}

// 格式化手机号 （格式：133 3333 3333）
export function fmtPhoneNum(phoneNum) {
  return phoneNum.substring(0, 3) + " " + phoneNum.substring(3, 7) + " " + phoneNum.substring(7, 11);
}
// 对象数据重置 参数1：{data:'22sd'} 返回：{data:''}
export function objdata_ret (data){
  for(let key in data){
    data[key]=''
  }
  return data
}
// 匹配字段名获取对象数据 参数1：{data:''} 参数2：{data:'22',ss:'2'} 返回：{data:'22'}
export function objdata_set (data, obj) {
  for (let key in data) {
    data[key] = obj[key]
  }
  return data
}
