<template>
  <el-dialog class="operation_dialog" v-model="dialogVisible" :title="dialogVisibletitle" :close-on-click-modal="false" width="560px">
    <template v-if="dialogVisibletitle === '新增企业' || dialogVisibletitle === '修改企业'">
      <div class="dp-f">
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon label-width="90px"
          class="demo-ruleForm w100" label-position="left">
          <el-form-item label="企业名称" prop='name'>
            <el-input v-model="ruleForm.name" :maxlength="config.inputMaxlength" show-word-limit
              placeholder="请输入企业名称" :disabled="dialogVisibletitle==='修改企业'"/>
          </el-form-item>
          <el-form-item label="公司社会信用代码" prop='code' class="is-required">
            <el-input v-model="ruleForm.code" :maxlength="config.inputMaxlength" show-word-limit
              placeholder="请输入公司社会信用代码" :disabled="dialogVisibletitle==='修改企业'"/>
          </el-form-item>
          <el-form-item label="联系人" prop='transactorName'>
            <el-input v-model="ruleForm.transactorName" :maxlength="config.inputMaxlength" show-word-limit
              placeholder="请输入联系人" />
          </el-form-item>
          <el-form-item label="联系方式" prop='transactorPhone' class="is-required">
            <el-input v-model="ruleForm.transactorPhone" :maxlength="config.inputMaxlength" show-word-limit
              placeholder="请输入联系人的手机号" />
          </el-form-item>
          <el-form-item label="联系人部门" prop='transactorPost'>
            <el-input v-model="ruleForm.transactorPost" :maxlength="config.inputMaxlength" show-word-limit
              placeholder="请输入联系人部门" />
          </el-form-item>
          <el-form-item label="备注" prop='remark'>
            <el-input v-model="ruleForm.remark" :maxlength="config.inputMaxlength" show-word-limit
              placeholder="请输入备注" />
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" width='120' height='40' title="确定" CSStype=2 @buttonclick="submitForm()"
          v-model:loading="dialogloading"></oabutton>
        <oabutton class="searcML" width='120' height='40' CSStype=3 title="取消" @buttonclick="dialogVisible = false"></oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits } from 'vue'
import { objdata_ret, objdata_set } from "@/utils/server/format";
import { handleMessage } from "@/utils/server/confirm";
import { httpToken } from "@/utils/request";
import { validPhone,validSocialCode } from "@/utils/server/validate.js";
import qs from "qs";
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const dialogVisibletitle = ref(false)//弹框标题
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const state = reactive({
})
const ruleFormRef = ref(null);//表单ref
const ruleForm = reactive({//from 表单数据
  name:'',//企业名称
  code:'',//公司社会信用代码
  transactorName:'',//联系人姓名
  transactorPhone:'',//联系方式 手机号
  transactorPost:'',//联系人职位
  remark:'',//备注
})
// 校验手机号格式
const validPhoneData = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请输入联系人的手机号'))
    } else {
        if (validPhone(value)) {
          callback()
        } else
          callback(new Error('手机号格式错误'))
    }
}
// 校验统一社会信用代码
const validSocialCodeData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入公司社会信用代码'))
  } else {
    if (validSocialCode(value)) {
      callback()
    } else
      callback(new Error('统一社会信用代码格式错误'))
  }
}
const rules = reactive({//from 表单校验
  name: [
    { required: true, message: '请输入企业名称', trigger: 'blur' },
  ],
  code: [
    { validator: validSocialCodeData, trigger: 'blur' }
  ],
  transactorName: [
    { required: true, message: '请输入联系人姓名', trigger: 'blur' },
  ],
  transactorPhone: [
    { validator: validPhoneData, trigger: 'blur' }
  ],
})
// 表单重置
let fromDataReset = (() => {
  objdata_ret(ruleForm)
})
let add = (() => {
  fromDataReset()
  dialogloading.value = false
  dialogVisibletitle.value = '新增企业'
  dialogVisible.value = true
  nextTick(() => {
    unref(ruleFormRef).clearValidate()//清除表单验证结果
  })
})
let editclick = ((row) => {
  fromDataReset()
  objdata_set(ruleForm, row)
  id.value = row.id
  dialogloading.value = false
  dialogVisibletitle.value = '修改企业'
  dialogVisible.value = true
  nextTick(() => {
    unref(ruleFormRef).clearValidate()//清除表单验证结果
  })
})
// 表单提交
const submitForm = async () => {
  dialogloading.value = true
  const form = unref(ruleFormRef)
  if (!form) {
    dialogloading.value = false
    return
  }
  await form.validate((valid) => {
    if (valid) {
      if (dialogVisibletitle.value === '新增企业') {
        dataGeneration({
          ...ruleForm,
          type:1
        })
      } else if (dialogVisibletitle.value === '修改企业') {
        dataGeneration({
          id: id.value,
          ...ruleForm,
          type:1
        }, '/admin/addressbook/update')
      }
    } else {
      dialogloading.value = false
    }
  })
}
// 数据生成
let dataGeneration = ((json, url = '/admin/addressbook/add') => {
  httpToken({
    method: "post",
    url: url,
    data: json,
  }).then((res) => {
    dialogloading.value = false
    dialogVisible.value = false
    emit('Emit')
  }).catch(() => {
    dialogloading.value = false
  })
})
defineExpose({
  add,
  editclick,
});
</script>